
import { defineComponent } from 'vue';
import { range, t } from '@/utils/utils';
import { useMeta } from 'vue-meta';

/**
 *
 * @version 1.0.0
 * @since
 */
export default defineComponent({
	name: 'About',
	setup() {
		useMeta({
			title: t('nav.about'),
		})
		return {
			range,
		};
	},
});
